import axios from "@axios";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchVoiceCallLogs(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/voice-call-logs", { params: queryParams })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        fetchVoiceCallLogStatus() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/list/voice-call-logs-status")
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        fetchVoiceCallLog(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`voice-call-logs/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        addVoiceCallLog(ctx, userData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("voice-call-logs", userData, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        console.log(error);
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        updateVoiceCallLog(ctx, voice_call_log) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`voice-call-logs/${voice_call_log.id}`, voice_call_log.data, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        removeVoiceCallLog(ctx, userId) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`voice-call-logs/${userId}`)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        removeMultipleVoiceCallLogs(ctx, ids) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/voice-call-logs/destroy`, { ids: ids })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        getCustomFields() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/voice-call-logs/create")
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
    }
};
